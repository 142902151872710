import { Box } from "@mui/system";
import logo from "../assets/logowithname.png";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router";

export default function ErrorPage() {
  let navigate = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: `black`,
        width: "100%",
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Box sx={{ height: "10vh" }}>
        <img
          src={logo}
          alt=""
          srcset=""
          style={{ width: "15vw", marginLeft: "70px", marginTop: "100px" }}
        />
      </Box>
      <Box
        sx={{
          mt: "-10vh",
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "#FFFFFF",
        }}
      >
        <Typography sx={{ fontSize: "13vw", fontWeight: "bold" }}>
          404
        </Typography>
        <Typography
          sx={{
            fontSize: "1.5vw",
            fontWeight: "bold",
            mt: { lg: "-7vh", md: "-4vh" },
          }}
        >
          Sorry, page not found
        </Typography>
        <Button
          style={{
            backgroundColor: "#0469CA",
            color: "#FFFFFF",
            borderRadius: "50px",
          }}
          sx={{
            mt: 2,
            px: "30px",
          }}
          onClick={() => navigate("/")}
        >
          Go Home
        </Button>
      </Box>
    </Box>
  );
}
