// import { Box, Button, TextField, Typography } from "@mui/material";
// import { ToastContainer, toast } from "react-toastify";
// import Logo from "../assets/Logo.png";
// import PersonIcon from "@mui/icons-material/Person";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import Cookies from "js-cookie";
// import { useFormik } from "formik";
// import { useState } from "react";
// import * as Yup from "yup";
// import { tvLoging } from "../sevice/loging";

// const { makeStyles } = require("@mui/styles");

// const useStyles = makeStyles((theme) => ({
//   main: {
//     height: "100vh",
//     backgroundColor: "#FFF",
//     display: "grid",
//     gridTemplateColumns: "1.3fr 1fr",
//     gridTemplateAreas: `
//     'leftPart rightPart'
//   `,
//   },
//   leftPart: {
//     background:
//       "linear-gradient(135deg, #14181D 2.08%, #3E3E3E 69.15%, #3E3E3E 94.04%)",
//   },
//   rightPart: {
//     background: "#FFFFFF",
//   },
//   rightElem: {
//     background: "#FFFFFF",
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   logoBox: {
//     width: "100%",
//     height: "100%",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//   },
//   logo: {
//     width: "30%",
//     height: "auto",
//   },
//   error_msg: {
//     color: "red",
//     fontSize: "1rem",
//     margin: 0,
//     fontFamily: "poppins",
//     display: "flex",
//     alignItems: "center",
//     justifyContent: "center",
//     pb: "2rem",
//   },
// }));
// function Login() {
//   const classes = useStyles();
//   const dispatch = useDispatch();
//   let navigate = useNavigate();
//   const [isLogin, setIsLogin] = useState(false);
//   const loginSchema = Yup.object({
//     password: Yup.string().required("Password is required"),
//   });
//   const formik = useFormik({
//     initialValues: {
//       adminId: "647598ff9bdbaec925685e42",
//       password: "",
//     },
//     validationSchema: loginSchema,
//     onSubmit: async (values, action) => {
//       setIsLogin(true);
//       const res = await tvLoging(values);
//       if (res.status) {
//         dispatch({ type: "LOGIN", payload: res });
//         Cookies.set("tv", JSON.stringify(res));
//         navigate("/");
//         action.resetForm();
//       } else {
//         setIsLogin(false);
//         toast.error(res);
//       }
//     },
//   });

//   return (
//     <>
//       <ToastContainer />
//       <Box className={classes.main}>
//         <Box className={classes.leftPart}>
//           <Box className={classes.logoBox}>
//             <img className={classes.logo} src={Logo} alt="logo" />
//           </Box>
//         </Box>
//         <Box className={classes.rightPart}>
//           <Box className={classes.rightElem}>
//             <Box>
//               <Box sx={{ pb: "2.1rem" }}>
//                 <Typography sx={{ fontSize: "2rem", fontWeight: "bold" }}>
//                   Hello Again!
//                 </Typography>
//                 <Typography sx={{ fontSize: "1.1rem" }}>
//                   Welcome Back
//                 </Typography>
//               </Box>
//               <Box component="form" onSubmit={formik.handleSubmit} noValidate>
//                 <Box sx={{ pb: "1rem" }}></Box>
//                 <Box sx={{ pb: "1rem" }}>
//                   <Box
//                     sx={{
//                       border: "1px solid #EEEEEE",
//                       width: "100%",
//                       height: "60px",
//                       borderRadius: "110px",
//                       display: "flex",
//                       alignItems: "center",
//                       boxSizing: "border-box",
//                       px: "1.5rem",
//                     }}
//                   >
//                     <PersonIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
//                     <TextField
//                       variant="outlined"
//                       type="text"
//                       placeholder="User ID"
//                       sx={{
//                         "& fieldset": { border: "none" },
//                         width: "100%",
//                       }}
//                       name="password"
//                       onBlur={formik.handleBlur}
//                       onChange={formik.handleChange}
//                       value={formik.values.password}
//                     />
//                   </Box>
//                 </Box>

//                 {formik.touched.password && formik.errors.password && (
//                   <p className={classes.error_msg}>{formik.errors.password}</p>
//                 )}
//                 <Box sx={{ pb: "1.5rem", pt: "1.1rem" }}>
//                   <Button
//                     type="submit"
//                     disabled={isLogin}
//                     sx={{
//                       background: "#0469CA",
//                       width: "100%",
//                       height: "60px",
//                       borderRadius: "110px",
//                       display: "flex",
//                       alignItems: "center",
//                       boxSizing: "border-box",
//                       px: "1.5rem",
//                       color: "white",
//                       "&:hover": {
//                         color: "white",
//                         background: "#0469CA",
//                       },
//                     }}
//                   >
//                     {isLogin ? (
//                       <Typography sx={{ color: "white", fontSize: ".9rem" }}>
//                         Loading
//                       </Typography>
//                     ) : (
//                       <Typography sx={{ color: "white", fontSize: ".9rem" }}>
//                         Login
//                       </Typography>
//                     )}
//                   </Button>
//                 </Box>
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </>
//   );
// }

// export default Login;

import { Box, Button, TextField, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../assets/bullionLog.png";
import io from "socket.io-client";
import PersonIcon from "@mui/icons-material/Person";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { tvLoging } from "../sevice/login";
import { deviceDetect } from "react-device-detect";
import { firebaseAuth } from "../sevice/config";
import CurrentLocation from "./CurrentLocation";
import { findLoaction, findUserIp } from "../sevice/home";
const adminId = process.env.REACT_APP_ADMIN_ID;

const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles((theme) => ({
  main: {
    height: "100vh",
    backgroundColor: "#FFF",
    display: "grid",
    gridTemplateColumns: "1.3fr 1fr",
    gridTemplateAreas: `
    'leftPart rightPart'
  `,
  },
  leftPart: {
    background:
      "linear-gradient(135deg, #14181D 2.08%, #3E3E3E 69.15%, #3E3E3E 94.04%)",
  },
  rightPart: {
    background: "#FFFFFF",
  },
  rightElem: {
    background: "#FFFFFF",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoBox: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "30%",
    height: "auto",
  },
  error_msg: {
    color: "red",
    fontSize: "1rem",
    margin: 0,
    fontFamily: "poppins",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pb: "2rem",
  },
}));
const loginSchema = Yup.object({
  password: Yup.string().required("Password is required"),
});

function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [userIp, setUserIp] = useState("Ip not found");
  const [latlng, setlatlng] = useState(null);
  const [location, setLocation] = useState("Unknown Address");
  const [deviceId, setDeviceId] = useState("");
  const [deviceName, setDeviceName] = useState("");

  const formik = useFormik({
    initialValues: {
      adminId: adminId,
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values, action) => {
      setIsLogin(true);
      const res = await tvLoging({
        adminId: adminId,
        deviceName: deviceName,
        password: values.password,
        deviceId: deviceId,
        deviceIp: userIp,
        loginLocation: location,
      });
      if (res.status) {
        dispatch({ type: "LOGIN", payload: res });
        Cookies.set("tv", JSON.stringify(res));
        Cookies.set("deviceId", JSON.stringify(deviceId));
        navigate("/");
        action.resetForm();
      } else {
        setIsLogin(false);
        toast.error(res);
      }
    },
  });

  function sample() {
    firebaseAuth
      .signInAnonymously()
      .then(async (user) => {
        setDeviceId(user?.user?.uid);
      })
      .catch(alert);
    const device = deviceDetect();
    if (device.isMobile) {
      setDeviceName(device.ua);
    } else {
      setDeviceName(device.userAgent);
    }
  }

  useEffect(() => {
    sample();
  }, []);
  useEffect(() => {
    if (latlng !== null) {
      handelFindLocation();
    }
  }, [latlng]);

  const handelFindLocation = async () => {
    const res = await findLoaction(latlng?.lat, latlng?.lng);
    if (res?.results[0]?.formatted_address) {
      setLocation(res?.results[0]?.formatted_address);
    }
  };
  useEffect(() => {
    handleFindUserIp();
  }, []);
  const handleFindUserIp = async () => {
    const res = await findUserIp();
    if (res?.ip) {
      setUserIp(res?.ip);
    }
  };

  return (
    <>
      <CurrentLocation setlatlng={setlatlng} />
      <Box className={classes.main}>
        <Box className={classes.leftPart}>
          <Box className={classes.logoBox}>
            <img className={classes.logo} src={Logo} alt="logo" />
          </Box>
        </Box>
        <Box className={classes.rightPart}>
          <Box className={classes.rightElem}>
            <Box>
              <Box sx={{ pb: "2.1rem" }}>
                <Typography
                  sx={{ fontSize: "2rem", fontWeight: "bold" }}
                  // onClick={() => handleLocationClick()}
                  // s
                >
                  Hello Again!
                </Typography>
                <Typography sx={{ fontSize: "1.1rem" }}>
                  Welcome Back
                </Typography>
              </Box>
              <Box component="form" onSubmit={formik.handleSubmit} noValidate>
                <Box sx={{ pb: "1rem" }}></Box>
                <Box sx={{ pb: "1rem" }}>
                  <Box
                    sx={{
                      border: "1px solid #EEEEEE",
                      width: "100%",
                      height: "60px",
                      borderRadius: "110px",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      px: "1.5rem",
                    }}
                  >
                    <PersonIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
                    <TextField
                      variant="outlined"
                      type="text"
                      placeholder="User ID"
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "100%",
                      }}
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  </Box>
                </Box>

                {formik.touched.password && formik.errors.password && (
                  <p className={classes.error_msg}>{formik.errors.password}</p>
                )}
                <Box sx={{ pb: "1.5rem", pt: "1.1rem" }}>
                  <Button
                    type="submit"
                    disabled={isLogin}
                    sx={{
                      background: "#0469CA",
                      width: "100%",
                      height: "60px",
                      borderRadius: "110px",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      px: "1.5rem",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        background: "#0469CA",
                      },
                    }}
                  >
                    {isLogin ? (
                      <Typography sx={{ color: "white", fontSize: ".9rem" }}>
                        Loading
                      </Typography>
                    ) : (
                      <Typography sx={{ color: "white", fontSize: ".9rem" }}>
                        Login
                      </Typography>
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Login;
