import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import bullionStats from "../assets/bullionStats.png";
import hello_Gold_Logo from "../assets/hello-Gold-Logo.png";
import LogoutIcon from "@mui/icons-material/Logout";
import bgImage from "../assets/Hello_gold_BG.png";
import { Box, Fade, Modal, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import Marquee from "react-fast-marquee";
import { useEffect } from "react";
import io from "socket.io-client";
import Carousel from "react-material-ui-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
import Cookies from "js-cookie";
import {
  allCommodities,
  allNewses,
  findBanners,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import moment from "moment-timezone";
import { loginChecking, logoutTv } from "../sevice/login";
import SubscriptionExpiringSoon from "./SubscriptionExpiringSoon copy";
import SubscriptionExpired from "./SubscriptionExpired";
import LimitExceededModal from "./LimitExceededModal";
// const socket = io("https://testsocket.bullionview.com/");
const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;
let socket = Cookies.get("socketUrl")
  ? io(JSON.parse(Cookies.get("socketUrl")))
  : null;

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    // gridTemplateRows: "25vh 55vh 10vh 10vh",
    // gridTemplateAreas: `
    //   'header'
    //   'body'
    //   'stats'
    //   'updates'
    // `,
    backgroundImage: `url(${bgImage})`,
    height: "100vh",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    maxWidth: "2300px",
    width: "100vw",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // [theme.breakpoints.down("md")]: {
    //   gridTemplateColumns: "1fr",
    //   gridTemplateRows: "60vh 100vh auto auto",
    //   gridTemplateAreas: `
    //     'header'
    //     'body'
    //     'stats'
    //     'updates'
    //   `,
    // },
  },
  header: {
    height: "25%",
    gridArea: "header",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr 2fr",
    gridTemplateAreas: `
      'logo banner'
    `,

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 2fr",
      gridTemplateAreas: `
        'logo'
        'banner'
      `,
    },
  },
  logo: {
    gridArea: "logo",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "1fr",
    height: "25vh",
  },
  logoImg: {
    width: "80%",
    height: "90%",
    objectFit: "contain",
    paddingLeft: "1em",
  },
  banner: {
    gridArea: "banner",
    width: "2fr",
    height: "25vh",
    display: "grid",
    gridTemplateColumns: "2.5fr 1fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
      'bannerImg dateTime'
    `,
    gap: "1em",
    paddingRight: "1em",

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr",
      gridTemplateAreas: `
        'bannerImg'
        'dateTime'
      `,
    },
  },
  bannerImg: {
    gridArea: "bannerImg",
    width: "100%",
    objectFit: "contain",
    height: "25vh",
    padding: "1em",
    boxSizing: "border-box",
  },
  dateTime: {
    gridArea: "dateTime",
    color: "#D3AA4C",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  body: {
    height: "55%",
    gridArea: "body",
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
      'spotRate table'
    `,
    // gap: '2em',
    // padding: '1em',
    // boxSizing: 'border-box',
    height: "55vh",

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr",
      gridTemplateAreas: `
        'spotRate'
        'table'
      `,
    },
  },
  spotRate: {
    gridArea: "spotRate",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 5fr",
    gridTemplateAreas: `
      'spotRateHeader' 
      'spotRateBox'
    `,
    gap: "1em",
    padding: "1em",
    boxSizing: "border-box",
    height: "55vh",
  },
  spotRateHeader: {
    gridArea: "spotRateHeader",
    backgroundColor: "#D3AA4C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spotRateBox: {
    gridArea: "spotRateBox",
    border: "1px solid #D3AA4C",
    borderRadius: "5px",
    background: "#2F2E2C",
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `
      'spotRateBoxGold'
      'spotRateBoxSilver'
    `,
  },
  spotRateBoxGold: {
    gridArea: "spotRateBoxGold",
    color: "#D3AA4C",
    display: "grid",
    gridTemplateRows: "1fr 3fr",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `
      "spotRateBoxGoldRow1"
      "spotRateBoxGoldRow2"
    `,
  },
  spotRateBoxGoldRow1: {
    gridArea: "spotRateBoxGoldRow1",
    display: "flex",
    justifyContent: "center",
  },
  spotRateBoxGoldRow2: {
    gridArea: "spotRateBoxGoldRow2",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
      'spotRateBoxGoldRow2Col1 spotRateBoxGoldRow2Col2'
    `,
    marginTop: "-0.7em",
  },
  spotRateBoxGoldRow2Col1: {
    gridArea: "spotRateBoxGoldRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
  },
  spotRateBoxGoldRow2Col2: {
    gridArea: "spotRateBoxGoldRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
  },
  spotRateBoxSilver: {
    gridArea: "spotRateBoxSilver",
    color: "#D3AA4C",
    display: "grid",
    gridTemplateRows: "1fr 3fr",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `
      "spotRateBoxSilverRow1"
      "spotRateBoxSilverRow2"
    `,
  },
  spotRateBoxSilverRow1: {
    gridArea: "spotRateBoxSilverRow1",
    display: "flex",
    justifyContent: "center",
  },
  spotRateBoxSilverRow2: {
    gridArea: "spotRateBoxSilverRow2",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
      'spotRateBoxSilverRow2Col1 spotRateBoxSilverRow2Col2'
    `,
    marginTop: "-0.7em",
  },
  spotRateBoxSilverRow2Col1: {
    gridArea: "spotRateBoxSilverRow2Col1",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
  },
  spotRateBoxSilverRow2Col2: {
    gridArea: "spotRateBoxSilverRow2Col2",
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
  },
  table: {
    gridArea: "table",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr 5fr",
    gridTemplateAreas: `
      'tabeHeader' 
      'tableContent'
    `,
    gap: "1em",
    padding: "1em",
    boxSizing: "border-box",
    height: "55vh",
  },
  tabeHeader: {
    gridArea: "tabeHeader",
    backgroundColor: "#D3AA4C",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "center",
    fontSize: "2vw",
  },
  tableRowColumn1: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "15%",
    flexGrow: 1,
    gridArea: "tableRow1",
    border: "1px solid #D3AA4C",
    borderRadius: "5px",
    background: "#2F2E2C",
    display: "flex",
  },
  stats: {
    height: "10%",
    gridArea: "stats",
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gridTemplateRows: "1fr",
    gridTemplateAreas: `
      'web percent'
    `,

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "1fr 1fr",
      gridTemplateAreas: `
        'web'
        'percent'
      `,
    },
  },
  web: {
    gridArea: "web",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  webImg: {
    width: "80%",
    height: "80%",
    objectFit: "contain",

    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "50px",
      boxSizing: "border-box",
    },
  },
  percent: {
    gridArea: "percent",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  updates: {
    height: "10%",
    color: "#fff",
    display: "flex",
    color: "#FFFFFF",
    maxHidth: "100vw",
    paddingRight: "1em",
    paddingLeft: "1em",
    paddingBottom: "1em",
    alignItems: "end",
  },
  updatesHeader: {
    background: "#D3AA4C",
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    height: "70%",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#80704D",
    height: "70%",
    width: "85%",
  },
  blur: {
    filter: "blur(8px)",
  },
}));

const HomePage = () => {
  const classes = useStyles();
  const { tv } = useSelector((state) => ({ ...state }));
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [commodities, setCommodities] = useState([]);
  const [remainingTime, setRemainingTime] = useState(null);
  const [newes, setNews] = useState([]);
  const [banners, setBanners] = useState([]);
  const [time, setTime] = useState(new Date());
  const [openlimitExpierd, setOpenlimitExpierd] = useState(false);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);

  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });
  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [gold, setGold] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [silver, setSilver] = useState({
    ask: 0,
    bid: 0,
    highPrice: 0,
    lowPrice: 0,
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });
  const [previousSilver, setPreviousSilver] = useState();
  const [previousGold, setPreviousGold] = useState();

  useEffect(() => {
    hadlefetchNews();
  }, []);

  useEffect(() => {
    hadlefetchBanner();
  }, []);

  useEffect(() => {
    handleFindRatio();
  }, []);

  useEffect(() => {
    handleFindSpread();
  }, []);

  useEffect(() => {
    handleFinsCommodities();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerExpire();
    }, 60 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 20 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    handleFindLiveValueTypeForDisplay();
  }, []);

  useEffect(() => {
    handleloginChecking();
  }, []);

  useEffect(() => {
    handlecheckSubscriptionExpierd();
  }, []);

  useEffect(() => {
    handleFindSocketUrl();
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        setIsMarketOpen(data.data.isMarketOpen);
        setPreviousGold(gold);
        setGold({
          ask: data.data.ask,
          bid: data.data.bid,
          highPrice: data.data.highPrice,
          lowPrice: data.data.lowPrice,
        });
      });
      socket.on("silver-rate-change", (data) => {
        setPreviousSilver(silver);
        setSilver({
          ask: data.data.ask,
          bid: data.data.bid,
          highPrice: data.data.highPrice,
          lowPrice: data.data.lowPrice,
        });
      });
    }
  });

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      handleloginChecking();
    });
    socket2.on("mediaRerender", (data) => {
      hadlefetchBanner();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleLogout();
    });
    socket2.on("socketRerender", (data) => {
      window.location.reload();
    });
    socket2.on("bidAndAskTitleRerender", (data) => {
      handleFindLiveValueTypeForDisplay();
    });
  }, []);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );

        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        if (elapsedTime <= 0) {
          clearInterval(timerId);
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      Cookies.set("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);
    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };
  const handleOpen = () => setOpenSubscription(true);
  const handleClose = () => setOpenSubscription(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (res) {
      setCommodities(res.commodities);
    }
  };

  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  async function triggerExpire() {
    const givenDate = new Date(tv?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();
    if (b >= a) {
      console.log(b >= a);
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleOpen();
      }
    }
  }

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  };

  const handlecheckSubscriptionExpierd = () => {
    const givenDate = new Date(tv?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();

    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res) {
      setRatio(res);
    }
  };

  const hadlefetchBanner = async () => {
    const res = await findBanners();
    if (res?.length) {
      setBanners(res);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (res) {
      setNews(res);
    }
  };

  const handleLogout = async () => {
    const deviceId = Cookies.get("deviceId")
      ? JSON.parse(Cookies.get("deviceId"))
      : null;
    const res = await logoutTv({
      adminId: adminId,
      deviceId: deviceId,
    });
    if (res) {
      Cookies.set("tv", "");
      Cookies.set("deviceId", "");
      Cookies.remove("socketUrl");
      window.location.reload();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/login");
    }
  };

  const handleloginChecking = async () => {
    const deviceId = Cookies.get("deviceId")
      ? JSON.parse(Cookies.get("deviceId"))
      : null;
    const res = await loginChecking(deviceId);
    if (res.status === false && res?.totalLoginCount <= res?.liveCounts) {
      setOpenlimitExpierd(true);
    } else if (res.status === false) {
      Cookies.set("tv", "");
      Cookies.set("deviceId", "");
      Cookies.remove("socketUrl");
      window.location.reload();
      dispatch({
        type: "LOGOUT",
      });
      navigate("/login");
    }
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const formatDate = (date) => {
    return date
      .toLocaleString([], {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(",", "");
  };
  const formatDay = (date) => {
    return date.toLocaleString([], { weekday: "long" });
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);
  const buyersColor = "#5FA247";
  const sellersColor = "#DE5B56";
  const progressStyle = {
    backgroundColor: sellersColor,
  };
  const barStyle = {
    backgroundColor: buyersColor,
  };

  return (
    <div
      className={(openlimitExpierd || openSubscriptionExpierd) && classes.blur}
    >
      {!isMarketOpen && (
        <Box
          sx={{
            position: "fixed",
            top: "0",
            left: "-125px",
            right: "0",
            bottom: "0",
            width: "895px",
            height: "531px",
            overflow: "hidden",
            zIndex: "99",
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "10%",
              transform: "rotate(-35deg)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#80704D",
              color: "#FFFFFF",
              fontSize: "2vw",
              fontWeight: "bold",
              padding: "20px",
              lineHeight: 1.5,
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "space-evenly",
            }}
          >
            <Marquee delay="2" gradient={false}>
              {remainingTime && (
                <p style={{ marginLeft: "90px" }}>
                  Market closed! Opens on {formatRemainingTime(remainingTime)}
                </p>
              )}
            </Marquee>
          </div>
        </Box>
      )}
      <Box
        className={classes.parentContainer}
        sx={{
          filter: tv?.liveUsers >= tv?.loginCount ? "blur(5px)" : "",
        }}
      >
        <Box className={classes.header}>
          <Box className={classes.logo}>
            <img
              className={classes.logoImg}
              src={hello_Gold_Logo}
              alt="bullionStats"
            />
          </Box>
          <Box className={classes.banner}>
            <Box className={classes.bannerImg}>
              <Carousel
                animation="fade" // Set the animation type to slide
                navButtonsAlwaysVisible={false} // Show navigation buttons always
                interval={10000}
                indicatorContainerProps={{
                  style: {
                    display: "none",
                  },
                }}
                sx={{
                  height: "23vh",
                }}
                transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                autoPlay
              >
                {banners?.map((row, i) => (
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      maxHeight: "23vh",
                      objectFit: "cover",
                      borderRadius: "20px",
                    }}
                    src={row.imageUrl}
                    alt="banner"
                  />
                ))}
              </Carousel>
            </Box>

            <Box className={classes.dateTime}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "2.2vw",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {formatTime(time)}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.4vw",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {formatDay(time)}
              </Typography>
              <Typography
                sx={{
                  fontSize: "2.1vw",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {formatDate(time)}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.body}>
          <Box className={classes.spotRate}>
            <Box className={classes.spotRateHeader}>
              <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                S P O T &nbsp; R A T E
              </Typography>
            </Box>
            <Box className={classes.spotRateBox}>
              <Box className={classes.spotRateBoxGold}>
                <Box className={classes.spotRateBoxGoldRow1}>
                  <Typography
                    sx={{
                      fontSize: "1.4vw",
                      fontWeight: "bold",
                      "@media (max-width: 900px)": { fontSize: "4.4vw" },
                    }}
                  >
                    GOLD
                  </Typography>
                </Box>
                <Box className={classes.spotRateBoxGoldRow2}>
                  <Box className={classes.spotRateBoxGoldRow2Col1}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            color: "#222121",
                            background: "#D3AA4C",
                            px: "7px",
                            display: "inline-block",
                            width: "fit-content",
                            borderRadius: "4px",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "1vw", fontWeight: "bold" }}
                          >
                            $
                          </Typography>
                        </Box>
                        <Typography
                          sx={{ fontSize: "1.4vw", fontWeight: "bold" }}
                        >
                          {displayBidOrBuy.bidOrBuy.toUpperCase()}
                        </Typography>
                        <Typography
                          style={{ fontSize: "1.2vw", color: "#fff" }}
                        >
                          oz
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          background:
                            Number(gold?.bid).toFixed(2) <
                            Number(previousGold?.bid).toFixed(2)
                              ? "#DE5B56"
                              : Number(gold?.bid).toFixed(2) >
                                Number(previousGold?.bid).toFixed(2)
                              ? "#5FA147"
                              : "#2F2E2C",
                          border: "1px solid #fff",
                          color: "#fff",
                          borderRadius: "5px",
                          display: "inline-block",
                          width: "fit-content",
                          px: 1.5,
                          width: "8.8vw",
                        }}
                      >
                        <Typography sx={{ fontSize: "2.3vw", fontWeight: 700 }}>
                          {(
                            Number(spread.goldBidSpread) + Number(gold.bid)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#DE5B56",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography style={{ fontSize: "1vw" }}>
                            Low
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontSize: "1.3vw", color: "#fff" }}
                        >
                          {(
                            Number(spread.goldLowSpread) + Number(gold.lowPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box className={classes.spotRateBoxGoldRow2Col2}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            color: "#222121",
                            background: "#D3AA4C",
                            px: "7px",
                            display: "inline-block",
                            width: "fit-content",
                            borderRadius: "4px",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "1vw", fontWeight: "bold" }}
                          >
                            $
                          </Typography>
                        </Box>
                        <Typography
                          sx={{ fontSize: "1.4vw", fontWeight: "bold" }}
                        >
                          {displayBidOrBuy.askOrSell.toUpperCase()}
                        </Typography>
                        <Typography
                          style={{ fontSize: "1.2vw", color: "#fff" }}
                        >
                          oz
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          background:
                            Number(gold?.ask).toFixed(2) <
                            Number(previousGold?.ask).toFixed(2)
                              ? "#DE5B56"
                              : Number(gold?.bid).toFixed(2) >
                                Number(previousGold?.bid).toFixed(2)
                              ? "#5FA147"
                              : "#2F2E2C",
                          border: "1px solid #fff",
                          color: "#fff",
                          borderRadius: "5px",
                          display: "inline-block",
                          width: "fit-content",
                          px: 1.5,
                          width: "8.8vw",
                        }}
                      >
                        <Typography sx={{ fontSize: "2.3vw", fontWeight: 700 }}>
                          {(
                            Number(spread.goldAskSpread) + Number(gold.ask)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#5FA147",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography style={{ fontSize: "1vw" }}>
                            High
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontSize: "1.3vw", color: "#fff" }}
                        >
                          {(
                            Number(spread.goldHighSpread) +
                            Number(gold.highPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.spotRateBoxSilver}>
                <Box className={classes.spotRateBoxSilverRow1}>
                  <Typography
                    sx={{
                      fontSize: "1.4vw",
                      fontWeight: "bold",
                      "@media (max-width: 900px)": { fontSize: "4.4vw" },
                    }}
                  >
                    SILVER
                  </Typography>
                </Box>
                <Box className={classes.spotRateBoxSilverRow2}>
                  <Box className={classes.spotRateBoxSilverRow2Col1}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            color: "#222121",
                            background: "#D3AA4C",
                            px: "7px",
                            display: "inline-block",
                            width: "fit-content",
                            borderRadius: "3px",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "1vw", fontWeight: "bold" }}
                          >
                            $
                          </Typography>
                        </Box>
                        <Typography
                          sx={{ fontSize: "1.4vw", fontWeight: "bold" }}
                        >
                          {displayBidOrBuy.bidOrBuy.toUpperCase()}
                        </Typography>
                        <Typography
                          style={{ fontSize: "1.2vw", color: "#fff" }}
                        >
                          oz
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          background:
                            Number(silver?.bid).toFixed(2) <
                            Number(previousSilver?.bid).toFixed(2)
                              ? "#DE5B56"
                              : Number(silver?.bid).toFixed(2) >
                                Number(previousSilver?.bid).toFixed(2)
                              ? "#5FA147"
                              : "#2F2E2C",
                          border: "1px solid #fff",
                          color: "#fff",
                          borderRadius: "5px",
                          display: "inline-block",
                          width: "fit-content",
                          px: 1.5,
                          textAlign: "center",
                          width: "8.8vw",
                        }}
                      >
                        <Typography sx={{ fontSize: "2.3vw", fontWeight: 700 }}>
                          {(
                            Number(spread.silverAskSpread) + Number(silver.bid)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#DE5B56",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography style={{ fontSize: "1vw" }}>
                            Low
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontSize: "1.3vw", color: "#fff" }}
                        >
                          {(
                            Number(spread.silverLowSpread) +
                            Number(silver.lowPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box className={classes.spotRateBoxSilverRow2Col2}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            color: "#222121",
                            background: "#D3AA4C",
                            px: "7px",
                            display: "inline-block",
                            width: "fit-content",
                            borderRadius: "3px",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "1vw", fontWeight: "bold" }}
                          >
                            $
                          </Typography>
                        </Box>
                        <Typography
                          sx={{ fontSize: "1.4vw", fontWeight: "bold" }}
                        >
                          {displayBidOrBuy.askOrSell.toUpperCase()}
                        </Typography>
                        <Typography
                          style={{ fontSize: "1.2vw", color: "#fff" }}
                        >
                          oz
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          background:
                            Number(silver?.ask).toFixed(2) <
                            Number(previousSilver?.ask).toFixed(2)
                              ? "#DE5B56"
                              : Number(silver?.ask).toFixed(2) >
                                Number(previousSilver?.ask).toFixed(2)
                              ? "#5FA147"
                              : "#2F2E2C",
                          border: "1px solid #fff",
                          color: "#fff",
                          borderRadius: "5px",
                          px: 1.5,
                          display: "inline-block",
                          width: "fit-content",
                          width: "8.8vw",
                          textAlign: "center",
                        }}
                      >
                        <Typography sx={{ fontSize: "2.3vw", fontWeight: 700 }}>
                          {(
                            Number(spread.silverBidSpread) + Number(silver.ask)
                          ).toFixed(2)}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#5FA147",
                            color: "#fff",
                            px: 1,
                            display: "inline-block",
                            width: "fit-content",
                          }}
                        >
                          <Typography style={{ fontSize: "1vw" }}>
                            High
                          </Typography>
                        </Box>
                        <Typography
                          style={{ fontSize: "1.3vw", color: "#fff" }}
                        >
                          {(
                            Number(spread.silverHighSpread) +
                            Number(silver.highPrice)
                          ).toFixed(2)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.table}>
            <Box className={classes.tabeHeader}>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "2vw",
                  fontWeight: "bold",
                }}
              >
                COMMODITY
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "2vw",
                  fontWeight: "bold",
                }}
              >
                WEIGHT
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "2vw",
                  fontWeight: "bold",
                }}
              >
                BUY
                <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                  &nbsp;AED
                </Typography>
              </Typography>
              <Typography
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "2vw",
                  fontWeight: "bold",
                }}
              >
                SELL
                <Typography sx={{ fontSize: "0.9vw", mt: 2.2 }}>
                  &nbsp;AED
                </Typography>
              </Typography>
            </Box>

            <Box className={classes.tableContent}>
              {commodities?.map((commodity, idx) => {
                const words = commodity.commodity_title.split(" ");
                return (
                  <Box key={idx} className={classes.tableRow1}>
                    <Typography
                      sx={{
                        fontSize: "1.5vw",
                        // justifyContent: "left",
                        // pl: "2rem",
                      }}
                      className={classes.tableRowColumn1}
                    >
                      {words.map((word, index) => {
                        if (index === 0) {
                          return (
                            <span
                              key={index}
                              style={{
                                fontSize: "2vw",
                                fontWeight: "bold",
                                paddingLeft: "30px",
                              }}
                            >
                              {word.toUpperCase()}
                            </span>
                          );
                        } else {
                          return (
                            <span
                              key={index}
                              style={{
                                fontSize: "1vw",
                                marginTop: "0.2rem",
                                fontWeight: "bold",
                              }}
                            >
                              &nbsp;{word.toUpperCase()}
                            </span>
                          );
                        }
                      })}
                      &nbsp;
                      {commodity.unitLabel === "TTB"
                        ? ""
                        : commodity.displayPurity}
                    </Typography>
                    <Typography
                      style={{ fontSize: "2vw", fontWeight: "bold" }}
                      className={classes.tableRowColumn}
                    >
                      {commodity.unit}
                      {commodity.unitLabel}
                    </Typography>
                    <Typography
                      style={{ fontSize: "2vw", fontWeight: "bold" }}
                      className={classes.tableRowColumn}
                    >
                      {commodityCalculation(
                        commodity.commodity_title === "Silver"
                          ? silver.bid
                          : gold.bid,
                        commodity.commodity_title === "Silver"
                          ? spread.silverBidSpread
                          : spread.goldBidSpread,
                        commodity.buy_premium,
                        3.674,
                        commodity.purity,
                        commodity.unit,
                        commodity.unitLabel === "TTB"
                          ? 116.64
                          : commodity.unitLabel === "KG"
                          ? 1000
                          : commodity.unitLabel === "OZ"
                          ? 31.1
                          : commodity.unitLabel === "TOLA"
                          ? 11.7
                          : 1,
                        commodity.buy_charge
                      )}
                    </Typography>
                    <Typography
                      style={{ fontSize: "2vw", fontWeight: "bold" }}
                      className={classes.tableRowColumn}
                    >
                      {commodityCalculation(
                        commodity.commodity_title === "Silver"
                          ? silver.ask
                          : gold.ask,
                        commodity.commodity_title === "Silver"
                          ? spread.silverAskSpread
                          : spread.goldAskSpread,
                        commodity.premium,
                        3.674,
                        commodity.purity,
                        commodity.unit,
                        commodity.unitLabel === "TTB"
                          ? 116.64
                          : commodity.unitLabel === "KG"
                          ? 1000
                          : commodity.unitLabel === "OZ"
                          ? 31.1
                          : commodity.unitLabel === "TOLA"
                          ? 11.7
                          : 1,
                        commodity.charges
                      )}
                    </Typography>
                  </Box>
                );
              })}

              {/* <Box className={classes.tableRow2}>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>GOLD &nbsp;
                <Typography style={{ fontSize: "0.8vw", marginTop: "0.2rem" }}>TEN TOLA</Typography>
              </Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>TTB</Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>25,330</Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>25,330</Typography>
            </Box>

            <Box className={classes.tableRow3}>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>GOLD &nbsp;
                <Typography style={{ fontSize: "0.9vw", marginTop: "0.2rem" }}>995</Typography>
              </Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>1KG</Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>2,17,331</Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>2,17,331</Typography>
            </Box>

            <Box className={classes.tableRow4}>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>GOLD &nbsp;
                <Typography style={{ fontSize: "0.9vw", marginTop: "0.2rem" }}>9999</Typography>
              </Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>1KG</Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>2,17,331</Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>2,17,331</Typography>
            </Box>

            <Box className={classes.tableRow5}>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>SILVER</Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>1KG</Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>2,17,331</Typography>
              <Typography style={{ fontSize: '1.5vw' }} className={classes.tableRowColumn}>2,17,331</Typography>
            </Box> */}
            </Box>
          </Box>
        </Box>

        <Box className={classes.stats}>
          <Box className={classes.web}>
            <a href="https://www.bullionstats.com/">
              <img
                className={classes.webImg}
                src={bullionStats}
                alt="bullionStats"
              />
            </a>
          </Box>
          <Box className={classes.percent} sx={{}}>
            <Box sx={{ width: "55%", paddingX: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  // width: "50%",
                  px: 5,
                  py: 1,
                  boxSizing: "border-box",
                }}
              >
                <Typography sx={{ fontSize: "1.3vw" }}>BUYERS</Typography>
                <Typography
                  sx={{
                    color:
                      Number(ratio?.chgValue?.replace("%", "")) >= 0
                        ? "#5FA247"
                        : "#DE5B56",
                    fontSize: "1.3vw",
                  }}
                >
                  {ratio?.chgValue}
                </Typography>
                <Typography sx={{ fontSize: "1.3vw" }}>SELLERS</Typography>
              </Box>
              <Box
              //  sx={{ width: "50%" }}
              >
                <LinearProgress
                  variant="determinate"
                  value={progress}
                  style={progressStyle}
                  sx={{
                    "& .MuiLinearProgress-bar": barStyle,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  px: 7,
                  py: 1,
                  boxSizing: "border-box",
                  color: "#5FA247",
                }}
              >
                <Typography sx={{ fontSize: "1.3vw" }}>
                  {ratio?.Buyers}
                </Typography>

                <Typography sx={{ fontSize: "1.3vw", color: "#DE5B56" }}>
                  {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.updates}>
          <Box className={classes.updatesHeader}>
            <Typography
              sx={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                textAlign: "center",
                my: "8px",

                color: "#222121",
              }}
            >
              Updates
            </Typography>
          </Box>
          <Box className={classes.updatesContent}>
            <Marquee
              // speed={15}
              // delay={0}
              delay="2"
              // style={{ height: "100%" }}
              gradient={false}
            >
              {newes?.map((item, index) => {
                return (
                  <div
                    key={item._id}
                    style={{
                      fontSize: "2vw",
                      fontFamily: "poppins",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>{item.newsTitle}</span>
                    &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                    <span style={{ paddingRight: "50px", paddingLeft: "50px" }}>
                      {index === newes.length - 1 ? "" : "|"}
                    </span>
                  </div>
                );
              })}
            </Marquee>
            <LogoutIcon
              sx={{ fontSize: "40px", color: "black" }}
              onClick={handleLogout}
            />
          </Box>
        </Box>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openlimitExpierd}
          closeAfterTransition
        >
          <Fade in={openlimitExpierd}>
            <Box>
              <LimitExceededModal logout={handleLogout} />
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openSubscription}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={openSubscription}>
            <Box>
              <SubscriptionExpiringSoon
                date={tv?.package.expire_date}
                handleClose={handleClose}
              />
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openSubscriptionExpierd}
          onClose={handleCloseSubscriptionExpierd}
          closeAfterTransition
        >
          <Fade in={openSubscriptionExpierd}>
            <Box>
              <SubscriptionExpired handleClose={handleLogout} />
            </Box>
          </Fade>
        </Modal>
      </Box>
    </div>
  );
};

export default HomePage;
